<template>
  <div class="dry-power">
    <moduleTitle :title="name"></moduleTitle>
    <div class="dry-power-content">
  
      <div class="main" v-for="item in dangnian" :key="item.id">
        <!-- 上面的 -->
        <div class="shang">
          <div>{{ item.entryTime }}</div>
          <div class="huida" v-show="!item.status">待回答</div>
        </div>
        <div>{{ item.content }}</div>
      </div>
    
    </div>
  </div>

</template>

<script>
import moduleTitle from "./components/moduleTitle.vue";
export default {
  components: {
    moduleTitle,
  },
  props: ["name","dangnian"],
  created() {},
  mounted() {},
  methods: {},
};
</script>

 <style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  color: #f0f6f5;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
.dry-power {
  position: relative;
  width: companyW(482vw);
  height: companyH(307vh);
  background: rgba(0, 20, 36,0.2);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #6d8a94, #6d8a94) 10 10;
  // opacity: 0.74;
  .dry-power-content {
    margin-top: companyH(25vh);
    max-height: companyH(270vh);
    overflow: auto;
  }
  .main {
    // height: companyH(80vh);
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    border-bottom: 1px dashed #8fa3cc;
    // margin-bottom: companyH(5vh);
    padding: companyW(15vw);
    .biaoti {
      font-size: companyW(20vw);
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
    .shang {
      display: flex;
      justify-content: space-between;
      margin-bottom: companyH(10vh) ;
      .shijian {
        font-size: companyW(16vw);
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #aecbe2;
      }
      .huida {
        width: companyW(54vw);
        height: companyH(20vh);
        background: #a3c6ce;
        border-radius: companyW(4vw);
        font-size:  companyW(14vw);
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: companyH(20vh);
      }
    }
  }
}
.dry-power-content::-webkit-scrollbar {
width:5px; /* 设置滚动条的宽度 */
// height: 10px; /* 设置滚动条的高度 */
// background-color: #F5F5F5; /* 设置滚动条的背景色 */
// border-radius: 5px; /* 设置滚动条的圆角 */
}
// .dry-power-content::-webkit-scrollbar-thumb {
// background-color: #8E9091; /* 设置滚动条的颜色 */
// border-radius: 5px; /* 设置滚动条的圆角 */
// }


</style>

