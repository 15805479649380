<template>
  <div class="dry-power">
    <moduleTitle :title="title"></moduleTitle>

    <div class="dry-power-content">
      <div
        class="box"
        @click="open(biaoshi, item.agricultural_type)"
        v-for="item in data"
        :key="item.agricultural_type"
      >
        <div class="imgBox">
          <img :src="item.icon" alt="" class="img" />
        </div>
        <div>
          <p class="num" style="font-size: 14px">
            {{ item.agricultural_type }}
          </p>
          <div style="display: flex; align-items: center">
            <div class="unit">{{ item.amount }}</div>
            <div class="zhong">种</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗1-->
    <Tanchuang
      :seeVisible="seeVisible"
      @closemask="closemask"
      :data="listData"
    ></Tanchuang>
  
  </div>
</template>

<script>
import moduleTitle from "./components/moduleTitle.vue";
import Tanchuang from "./tanchuang1.vue";

import {
  getVarietyDetail,
  getTechnologyDetail,

} from "@api/njfw/njfw.js";

export default {
  components: {
    moduleTitle,
    Tanchuang,
  
  },
  created() {},
  props: ["title", "data", "biaoshi"],
  data() {
    return {
      tabList: [],
      seeVisible: false,
      zhuanjiaVisible: false,
      listData: [], //品种和技术弹框数据
 
    };
  },
  mounted() {},
  methods: {
    // 打开弹窗
    open(biaoshi, name) {
      if (biaoshi == "新品种") {
        this.seeVisible = true;
        getVarietyDetail({
          name: name,
        })
          .then((res) => {
            this.listData = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (biaoshi == "新技术") {
        this.seeVisible = true;
        getTechnologyDetail({
          name: name,
        })
          .then((res) => {
            this.listData = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
 
    },
    closemask(item) {
      this.seeVisible = item;
    },
    zhuanjiaclose(item) {
      this.zhuanjiaVisible = item;
    },
  },
};
</script>

 <style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  color: #f0f6f5;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
.dry-power {
  position: relative;
  width: companyW(482vw);
  height: companyH(303vh);
  background: rgba(0, 20, 36, 0.4);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #6d8a94, #6d8a94) 10 10;
  // opacity: 0.74;
  .aa {
    display: flex;
    justify-content: center;
  }
  .dry-power-content {
    margin: 0 auto;
    margin-top: companyH(50vh);
    width: companyW(447vw);
    height: companyH(175vh);
    display: flex;
    flex-wrap: wrap;
    //  justify-content: space-between;
    padding: 0 companyW(11vw);
    .box {
      display: flex;
      align-items: center;
      width: 30%;
      margin-bottom: companyW(15vw);
      margin-right: companyW(11vw);
      .imgBox {
        border: 2px dashed;
        border-image: linear-gradient(0deg, #6d8a94, #6d8a94) 10 10;
        // opacity: 0.7;
        margin-right: companyW(8vw);
      }
      .img {
        width: companyW(60vw);
        height: companyW(60vw);
      }
      .num {
        font-size: companyW(14vw) !important;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        margin-bottom: companyW(8vw);
      }
      .unit {
        font-size: companyW(24vw);
        font-family: DIN;
        font-weight: 400;
        color: #ffffff;
        margin-right: companyW(6vw);
      }
      .zhong {
        font-size: 14px !important;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>

