import request from '@/utils/request.js';
// import axios from 'axios';

export function getConsult(params) {
  return request({
    url:'/experts/getConsult',
    method:'get',
    params
  });
}
export function getBasicInfo(params) {
  return request({
    url:'basic/getBasicInfo',
    method:'get',
    params
  });
}
export function getVideos(params) {
  return request({
    url:'video/getVideos',
    method:'get',
    params
  });
}
//     返回新品种类型和数量
export function getVarietyType(params) {
  return request({
    url:'/popularize/getVarietyType',
    method:'get',
    params
  });
}

//   返回新技术类型和数量
export function getTechnologyType(params) {
  return request({
    url:'/popularize/getTechnologyType ',
    method:'get',
    params
  });
}

//     根据新品种名称查询该品种下全部信息,传参：品种名称
export function getVarietyDetail(params) {
  return request({
    url:'/popularize/getVarietyDetail',
    method:'get',
    params
  });
}
//   根据新技术名称查询全部信息，传参：技术名称
export function getTechnologyDetail(params) {
  return request({
    url:'/popularize/getTechnologyDetail',
    method:'get',
    params
  });
}
//   获取农技专家小球数据
export function getTypeInfo(params) {
  return request({
    url:'experts/getTypeInfo',
    method:'get',
    params
  });
}

//   获取点击小球后的专家数据
export function getExpertsInfo(params) {
  return request({
    url:'/experts/getExpertsInfo',
    method:'get',
    params
  });
}
export function getServer(params) {
  return request({
    url:'/experts/getServer',
    method:'get',
    params
  });
}
// 省内咨询、国内咨询列表接口
export function consult(params) {
  return request({
    url:'/popularize/consult',
    method:'get',
    params
  });
}
// 根据资讯id查询具体信息
export function consultDetail(params) {
  return request({
    url:'/popularize/consultDetail',
    method:'get',
    params
  });
}
