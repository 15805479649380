<template>
  <div>
    <el-dialog
      :visible.sync="zhuanjiaVisible"
      :show-close="false"
      :modal="false"
    >
      <dv-border-box-1 :color="['#2a4879', '#162a39']">
        <div class="del" @click="zhuanjiaclose">
          <img src="../../assets/njfwimgs/guanbi.png" class="imgBox1" />
        </div>

        <div class="main">
          <div class="imgBox" style="margin-right: 10px">
            <img :src="data.avatar" class="img" />
          </div>
          <div class="right">
            <div>
              <span class="name">{{ data.name }}</span>
              <span class="shanchang">擅长:{{ data.skilled }}</span>
            </div>
            <div class="address">{{ data.flats }}</div>
            <div class="jianjie">专家简介:{{ data.synopsis }}</div>
          </div>
        </div>
      </dv-border-box-1>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["zhuanjiaVisible", "data"],
  mounted() {
   
  },
  methods: {
    zhuanjiaclose() {
      this.$emit("zhuanjiaclose", false);
    },
  },
};
</script>
  
  <style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

* {
  font-family: Source Han Sans CN;
  font-size: companyW(18vw);
  box-sizing: border-box;
  color: #fff;
}

::v-deep .el-dialog {
  width: companyW(600vw);
  height: companyH(605vh);
  background: #000000;
  border-radius: companyH(40vh);
  margin: 0 auto;
  // opacity: 0.9;
}

.dv-border-box-1 {
  position: relative;
  width: companyW(600vw);
  height: companyH(605vh);
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__header {
  padding: 0;
}
.del {
  position: absolute;
  top: companyH(30vh);
  right: companyH(30vh);
  width: companyW(20vw);
  height: companyH(20vh);
  // text-align: center;
  // line-height: companyH(35vh);
  // border-radius: 50%;
  // font-size: companyH(30vh);
  // color: #000;
  // font-weight: 500;
  // background-color: #fff;
}
.bestBox {
  // display: flex;
  // flex-wrap: wrap;
  // padding: companyH(28vh);
  // padding-top: companyH(60vh)  !important;
  // // justify-content: space-between;
  // // align-content: space-between;
  // max-height: companyH(605vh);
  // overflow: auto;
}
.main {
  width: companyW(419vw);
  height: companyH(169vh);
  background: #001424;
  border: 2px solid #a3c6ce;
  border-radius: companyH(10vh);
  padding: companyW(10vw);
  // margin-bottom: companyW(18vw);
  // margin-right: companyW(18vw);
  display: flex;
  position: absolute;

  top: 10%;
  left: 10%;
  // transform: translateX(-50%);
}
.img {
  width: companyW(60vw);
  height: companyH(60vh);
  border-radius: 50%;
}
.right {
  // margin-left: companyw(20vw);
  .name {
    font-size: companyw(20vw);
    font-family: PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
  .shanchang {
    font-size: companyw(16vw);
    font-family: DingTalk JinBuTi;
    font-weight: 400;
    color: #05c46d;
  }
  .address {
    font-size: companyw(18vw);
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    margin: companyH(10vh) 0 companyH(10vh) 0;
  }
  .jianjie {
    font-size: companyw(16vw);
    font-family: PingFang SC;
    font-weight: 500;
    color: #cccccc;
  }
}
.imgBox1 {
  width: companyW(20vw);
  height: companyH(20vh);
}
</style>
  