<template>
  <div class="njfw">

    <!-- 标题 -->
    <Title
      class="njfwTitle"
      njTitle="headBgc"
      typeOf="njfwimgs"
      :title="address+'农技服务'"
    ></Title>
    <div class="rightBgc anniu1" @click="jumpshouye">
      <img src="../../assets/njfwimgs/shouye.png" class="imgBox" />
      <p>首页</p>
    </div>
    <a class="rightBgc anniu2" href="http://nsfwgl.ifasun.com" target="_blank">
      <img src="../../assets/njfwimgs/houtai.png" class="imgBox" />
      <p>后台</p>
    </a>
    <div class="rightBgc anniu3" @click="toback">
      <img src="../../assets/njfwimgs/quanping.png" class="imgBox" />
      <p>全屏</p>
    </div>
    <div class="main">
      <!-- 左边三个 -->
      <div class="left">
        <Xinxi></Xinxi> 
        <Cpxl title="新品种" :data="pinzhong" biaoshi="新品种" ></Cpxl>
        <Cpxl title="新技术" :data="jishu" biaoshi="新技术"></Cpxl>
      </div>
      <!-- 中间视频和视频列表 -->
      <div class="middle">
       
        <Splb style="margin-top: 10px"></Splb>
      </div>

      <!-- 右边农技推广 -->
      <div class="right">
        <!-- <Cpxl title="农技专家" :data="zhuanjia"  biaoshi="专家"></Cpxl> -->
        <zhuanjia title="农技专家" style="z-index: 999;"></zhuanjia>
        <Zixun
          name="当年资讯记录"
          :dangnian="dangnian"

        ></Zixun>
        <!-- <Linian name="历年咨询纪录" :linian="linian" ></Linian> -->
        <Tubiao></Tubiao>
      </div>
    </div>
  </div>
</template>

<script>
import Title from './components/title.vue';
import Xinxi from './xinxi.vue';
import Cpxl from './cpxl.vue';
import Splb from './splb.vue';
import Zixun from './zixun.vue';
import Tubiao from './tubiao.vue';

// import Linian from "./linian.vue";
import zhuanjia from './zhuanjia.vue';
import { getConsult, getVarietyType,getTechnologyType } from '@api/njfw/njfw.js';
export default {
  name: 'njfw',
  components: {
    Title,
    Xinxi,
    Cpxl,
    Splb,
    Zixun,
    // Linian,
    zhuanjia,
    Tubiao
  },
  data() {
    return {
      address:'',
      dangnian: [],
      linian: [],
      pinzhong: [], //品种名称
      jishu:[], //技术名称
      zhuanjia:[], //专家名称
      
    };
  },
  created() {
    this.address = localStorage.getItem('address')
    this.getList();
    
  },
  
  mounted() {},
  methods: {
    getList() {
      getConsult({
        num: 0,
      })
        .then(res => {
          this.dangnian = res.data;
        })
        .catch(err => {
          console.log(err);
        });
      getConsult({
        num: 1,
      })
        .then(res => {
          this.linian = res.data;
        })
        .catch(err => {
          console.log(err);
        });
      // 返回新品种类型和数量
      getVarietyType({})
        .then(res => {
          this.pinzhong = res.data;
        })
        .catch(err => {
          console.log(err);
        });
      // 新技术
      getTechnologyType({})
        .then(res => {
          this.jishu = res.data;
        })
        .catch(err => {
          console.log(err);
        });

    },
    jumpshouye() {
      this.$router.push({
        path: '/',
      });
    },

    toback() {
      document.documentElement.requestFullscreen();
    },
  },
};
</script>

<style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  color: #f0f6f5;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
page {
  background-color: #161622;
}
.njfw {
  background-color: #1c2f3e;
  position: relative;
  width: companyW(1920vw);
  height: companyH(1080vh);
  overflow: hidden;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  .njfwTitle {
    padding-top: companyH(8vh);
    width: companyW(1820vw);
    height: companyH(70vh);
    margin: 0 auto;
  }
  .main {
    width: companyW(1920vw);
    display: flex;
    justify-content: space-around;
    height: companyH(1010vh);
    align-items: space-around;
  }
  .left,
  .right {
    height: companyH(990vh);
    width: companyW(482vw);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .middle {
    display: flex;
    flex-direction: column;
    margin-right: companyW(10vw);
    height: companyH(990vh);
    width: companyW(878vw);
    justify-content: space-between;
  }

                            
}
.rightBgc {
    width: companyW(90vw);
    height: companyH(30vh);
    background-color: #4a515a;
    border-radius: companyW(5vw) ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 companyW(10vw) 0 companyW(10vw);
    .imgBox {
      width: companyW(24vw);
      height: companyH(24vh);
    }
    p {
      font-size: companyH(18vh);
    }
  }
  .anniu1 {
    position: absolute;
    left:  companyW(30vw);
    top: companyH(8vh);
  }
  .anniu2 {
    position: absolute;
    left: companyW(134vw);
    top: companyH(8vh);
  }
  .anniu3 {
    position: absolute;
    right: companyW(30vw);
    top: companyH(8vh);
  }
</style>
