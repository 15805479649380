<template>
  <div class="dry-power">
    <moduleTitle :title="title" @changed="hanchange"></moduleTitle>
    <div class="zong">
   
      <div
        class="mainBox"
        v-for="item in zhuanjiaData"
        :key="item.id"
        @click="open(item)"
      >
        <div><img :src="item.avatar" class="imgBox" /></div>
        <div class="rightBox">
          <span>{{ item.name }}</span>
          <span>擅长:{{ item.skilled }}</span>
          <span>单位:{{ item.flats }}</span>
        </div>
      </div>
     
    </div>

    <!-- 弹窗2 -->
    <Zhuanjia
      :zhuanjiaVisible="zhuanjiaVisible"
      :data="itemData"
      @zhuanjiaclose="close"
    
    ></Zhuanjia>
  </div>
</template>
  
  <script>
import moduleTitle from "./components/moduleTitle.vue";

import Zhuanjia from "./tanchuang2.vue";
import { getExpertsInfo } from "@api/njfw/njfw.js";

export default {
  components: {
    moduleTitle,

    Zhuanjia,
  },
  created() {
    this.getTable();
  },
  props: ["title"],
  data() {
    return {
      zhuanjiaVisible: false,
      zhuanjiaData: [],
      itemData:''
    };
  },
  mounted() {},
  methods: {
    getTable() {
      getExpertsInfo({
       
      })
        .then((res) => {
          this.zhuanjiaData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    open(item) {
      this.itemData=item
      this.zhuanjiaVisible = true;
    },
    close(val){
      this.zhuanjiaVisible = val;

    },
    hanchange(val) {
      getExpertsInfo({
        skilledType:val
      })
        .then((res) => {
          this.zhuanjiaData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
  
    },
  },
};
</script>
  
   <style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
* {
  font-family: Source Han Sans CN;
  color: #f0f6f5;
  font-size: companyW(16vw);
  box-sizing: border-box;
}
.dry-power {
  position: relative;
  width: companyW(482vw);
  height: companyH(303vh);
  background: rgba(0, 20, 36, 0.2);
  border: companyW(1vw) solid;
  border-image: linear-gradient(0deg, #6d8a94, #6d8a94) 10 10;
  // opacity: 0.74;
  z-index: 999;
}
.zong {
  // height: 100%;
  max-height: 86%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: companyH(40vh);
}
.mainBox {
  display: flex;
  align-items: center;
  padding: 0 companyW(8vw) 0 companyW(13vw);
  margin-bottom: companyH(6vh);
}
.imgBox {
  width: companyW(102vw);
  height: companyH(97vh);
}
.rightBox {
  display: flex;
  flex-direction: column;
  line-height: companyH(32vh);
  margin-left: companyW(25vw);
  span:nth-child(1) {
    font-size: companyW(20vw);
    font-weight: 500;
    color: #ccdfff;
    margin-bottom: companyH(8vh);
  }
  span:nth-child(2) {
    width: companyW(334vw);
    height: companyH(32vh);
    background: rgba(67, 108, 200, 0.2);

    font-weight: 500;
  }
  span:nth-child(3) {
    width: companyW(334vw);
    height: companyH(32vh);
    background: rgba(67, 108, 200, 0.45);

    font-weight: 500;
  }
}
::-webkit-scrollbar {
  // 纵向滚动条和横向滚动条宽度
  width: companyW(10vw);
  height: companyH(10vh);
}
::-webkit-scrollbar-thumb {
  // 滚动条背景条样式
  border-radius: 1px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #fff;
}
::-webkit-scrollbar-track {
  // 滚动条按钮样式
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
  background: #5b5f63;
}

</style>
  
  