<template>
  <div>
    <el-dialog :visible.sync="seeVisible" :show-close="false" :modal="false">
      <dv-border-box-1 :color="['#2a4879', '#162a39']">
        <div class="del" @click="closexin">
          <img src="../../assets/njfwimgs/guanbi.png" class="imgBox" />
        </div>
        <div class="bestBox">
          <div class="main" v-for="item in data" :key="item.index">
            <div class="leftBox">
              <span>{{ item.agriculturalTitle }} </span>
              <span
                >{{ item.agriculturalOrigin }} {{ item.agriculturalDate }}</span
              >
            </div>
            <div class="aa">
              <img :src="item.agriculturalImage" class="img" />
            </div>
          </div>
        </div>
      </dv-border-box-1>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["seeVisible", "data"],
  mounted() {},
  methods: {
    closexin() {
      console.log(22);
      this.$emit("closemask", false);
    },
  },
};
</script>

<style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

* {
  font-family: Source Han Sans CN;
  font-size: companyW(18vw);
  box-sizing: border-box;
  color: #fff;
}

::v-deep .el-dialog {
  width: companyW(1369vw);
  height: companyH(605vh);
  background: #000000 !important;
  border-radius: companyH(40vh);
  margin: 0 auto;
  // opacity: 0.9;
}
::v-deep .el-dialog__body {
  // background-color:#142736;
  // border: 0;
  background: #000000;
  // opacity: 0.9;
}

.dv-border-box-1 {
  position: relative;
  width: companyW(1369vw);
  height: companyH(605vh);
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__header {
  padding: 0;
}
.del {
  position: absolute;
  top: companyH(30vh);
  right: companyH(30vh);
  width: companyW(20vw);
  height: companyH(20vh);
  // text-align: center;
  // line-height: companyH(35vh);
  // border-radius: 50%;
  // font-size: companyH(30vh);
  // color: #000;
  // font-weight: 500;
  // background-color: #fff;
}
.bestBox {
  display: flex;
  flex-wrap: wrap;
  padding: companyH(28vh);
  padding-top: companyH(60vh) !important;
  // justify-content: space-between;
  // align-content: space-between;
  max-height: companyH(605vh);
  overflow: auto;
}
.main {
  width: companyW(419vw);
  height: companyH(169vh);
  background: #001424;
  border: 2px solid #a3c6ce;
  border-radius: companyH(10vh);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: companyW(10vw);
  margin-bottom: companyW(18vw);
  margin-right: companyW(18vw);
}
.leftBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: companyH(116vh);
  margin-left: companyW(10vw);
  span:nth-child(1) {
    font-size: companyH(20vh);
    font-weight: bold;
    color: #ffffff;
  }
  span:nth-child(2) {
    font-size: companyH(18vh);
    font-weight: 500;
    color: #ffffff;
  }
}

.img,
.aa {
  width: companyW(116vw);
  height: companyH(116vh);
}
.imgBox {
  width: companyW(20vw);
  height: companyH(20vh);
}
</style>
