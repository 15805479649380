<template>
  <div>
    <el-dialog :visible.sync="seeVisible" :show-close="false" :modal="false">
      <dv-border-box-1 :color="['#2a4879', '#162a39']">
        <div class="del" @click="closexin">
          <img src="../../assets/njfwimgs/guanbi.png" class="imgBox" />
        </div>
        <div class="bestBox">
          <div style="margin: 0 auto">
            <img :src="listdata.img" alt="" />
          </div>
        </div>
      </dv-border-box-1>
    </el-dialog>
  </div>
</template>
  
  <script>
import { consultDetail } from "@api/njfw/njfw.js";
export default {
  components: {},
  data() {
    return {
      listdata: "",
    };
  },
  props: ["seeVisible", "id"],
  mounted() {},
  methods: {
    closexin() {
      this.$emit("closemask", false);
    },
  },
  watch: {
    id: {
      handler(newVal) {
        consultDetail({
          id: newVal,
        })
          .then((res) => {
            this.listdata = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
  
  <style scoped lang="scss">
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

* {
  font-family: Source Han Sans CN;
  font-size: companyW(18vw);
  box-sizing: border-box;
  color: #fff;
}

::v-deep .el-dialog {
  width: companyW(1000vw);
  height: companyH(800vh);
  background: #000000 !important;
  border-radius: companyH(40vh);
  margin: 0 auto;
  // opacity: 0.9;
}
::v-deep .el-dialog__body {
  // background-color:#142736;
  // border: 0;
  background: #000000;
  // opacity: 0.9;
}

.dv-border-box-1 {
  position: relative;
  width: companyW(1000vw);
  height: companyH(800vh);
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__header {
  padding: 0;
}
.del {
  position: absolute;
  top: companyH(30vh);
  right: companyH(30vh);
  width: companyW(20vw);
  height: companyH(20vh);
  // text-align: center;
  // line-height: companyH(35vh);
  // border-radius: 50%;
  // font-size: companyH(30vh);
  // color: #000;
  // font-weight: 500;
  // background-color: #fff;
}
.bestBox {
  display: flex;
  flex-wrap: wrap;
  padding: companyH(28vh);
  padding-top: companyH(60vh) !important;
  // justify-content: space-between;
  // align-content: space-between;
  max-height: companyH(605vh);
  overflow: auto;
}
.imgBox {
  width: companyW(20vw);
  height: companyH(20vh);
}
</style>
  