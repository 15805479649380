var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title",style:({
    'background-image': `url(${require('@/assets/' +
      _vm.typeOf +
      '/' +
      _vm.njTitle +
      '.png')})`,
  })},[_c('div',{staticClass:"title-desc"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }