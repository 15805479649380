<template>
  <div
    class="title"
    :style="{
      'background-image': `url(${require('@/assets/' +
        typeOf +
        '/' +
        njTitle +
        '.png')})`,
    }"
  >
  <div class="title-desc">
   {{ title }}
  </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true,
    },
    // 图片名字
    njTitle: {
      type: String,
      default: '',
    },
    // 静态文件夹下文件名
    typeOf: {
      type: String,
      default: '',
    },
  },
  methods: {

  },
};
</script>
  <style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}
@function companyH($num) {
  @return ($num * 100)/1080;
}
@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../assets/font/np4SotSdlbui.woff);
}
* {
  font-family: Source Han Sans CN;
  color: #f0f6f5;
  font-size: companyW(20vw);
  box-sizing: border-box;
}
.title {
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
  position: relative;
  .title-desc {
    margin-top: companyH(6vh);
    text-align: center;
    height: companyH(40vh);
    font-size: companyH(42vh);
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #fefefe;
    line-height: 100%;
  }

  .rightBgc {
    width: 90px;
    height: 30px;
    background-image: url(../../../assets/njfwimgs/fanhui.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    top: companyH(8vh);
    // opacity: 0.32;
  }
}


</style>

